import ApiService from "@/core/services/ApiService";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { CertificateItem } from "@/core/types/gws-global-feature/cert-verif/CertificateItem";

export const useCertVerifStore = defineStore({
  id: "certVerifStore",
  state: () => {
    return {
      stateLoading: false,
      stateCertificateDetail: {} as CertificateItem,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    certDetails(state) {
      return state.stateCertificateDetail;
    },
    error(state) {
      return state.stateError;
    },
  },
  actions: {
    async getCertificateDetail(certificateId: string) {
      this.stateError = false;
      this.stateLoading = true;
      this.stateCertificateDetail = {} as CertificateItem;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<CertificateItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `certificate-syarikah/${certificateId}`
          );
        const detail = response.data.data;
        this.stateCertificateDetail = detail;
      } catch (error) {
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    async approveCertificate() {
      this.stateError = false;
      this.stateLoading = true;
      const payload = {}
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.post(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/certificate-syarikah/${this.stateCertificateDetail.id}/cpmi/${this.stateCertificateDetail.cpmi_id}/approval`,
            payload as AxiosRequestConfig
          );
          if (response.data.meta.code === 200) {
            this.stateError = false;
          } else {
            this.stateError = true;
          }
      } catch(error) {
        this.stateError = true;
      }
      this.stateLoading = false;
    },
    resetData() {
      this.stateCertificateDetail = {} as CertificateItem;
    },
  },
});
